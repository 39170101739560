import React from "react";
/* eslint-disable react/prop-types */
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateSituationFamiliale } from "../Store/store";
import {InformationLeaveIbani} from "./information-leave-ibani/InformationLeaveIbani";

export default function SituationFamiliale(props) {
    console.log(props);
    return (
        <div className="page-container">
            <InformationLeaveIbani/>
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />

            <Page
                title="Quelle est votre situation familiale ?"
                subtitle="Pour affiner votre situation"
                pageType="picklist"
                values={["Célibataire", "Marié(e)/Pacsé(e)", "Divorcé(e)", "En concubinage", "Veuf(ve)"]}
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
                reduxStateName="situationFamiliale"
                reduxAction={updateSituationFamiliale}
                myNameDb="family_situation"
            />
        </div>
    );
}
