/* eslint-disable react/prop-types */
import React from "react";
import "./Header.scss";
import LogoLexem from "../../Pictures/logo_lexem.png";
import { simulateur_name } from "../../Config/config.jsx";
import styled, { keyframes } from "styled-components";

let progress = (begin, end) => keyframes`
  0% { width: ${begin}%; }
  100% { width: ${end}%; }
  `;

let Foreground = styled.div`
  width: ${(props) => props.begin}%;
  animation: ${(props) =>
        progress(props.begin, props.end)} 0.9s ease 0.2s forwards};
  `;

export default function Header(props) {
    return (
        <>
            <header className="header-simulation">
                <a href="https://lexem.io">
                    <img
                        className="logo-lexem"
                        alt="Logo de Lexem"
                        src={LogoLexem}
                    />
                </a>

                <h1>
                    Simulateur -{" "}
                    <span className="simulateur-name">{simulateur_name}</span>
                </h1>

                <div></div>
            </header>

            {props.showProgressBar === undefined ? (
                <div className="only-display-phone background">
                    <Foreground
                        begin={parseInt(
                            (props.pageIndex * 100) / props.totalPages
                        )}
                        end={parseInt(
                            ((props.pageIndex + 1) * 100) / props.totalPages
                        )}
                        className="foreground "
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
}
