/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../../Config/config";
import {
    putTempPageIndex,
    updateActualPageIndex,
    updateCapitalInitial,
    updateCounty,
    updateDuree,
    updateEmail,
    updateFirstName,
    updateLastName,
    updateObjectif,
    updateOptin,
    updatePhone,
    updatePhoneChecked,
    updateProfilInvestisseur,
    updateSessionId,
    updateSituationFamiliale,
    updateStartedSimulation,
    updateTempPageIndex,
    updateTimestamp,
    updateVersementAnnuel,
} from "../../../Store/store";
import Redirect from "../../Redirect";
import Modal from "./Modal";

function continueSimulation(dispatch) {
    dispatch(updateStartedSimulation("true"));
    dispatch(putTempPageIndex());
}

function startNewSimulation(dispatch) {
    fetch(
        backendUrl +
        "simulations/vie/startSimulation/?newSimulation=true",
        { credentials: "include", crossDomain: true }
    ).then((resp) => {
        resp.json().then((data) => {
            if (data.statut === "success") {
                dispatch(updateSessionId(data.session_id === null ? "" : data.session_id));
                dispatch(updateTimestamp(data.timestamp === null ? "" : data.timestamp));
                dispatch(updateSituationFamiliale(data.family_situation === null ? "" : data.family_situation));
                dispatch(updateCounty(data.county === null ? "" : data.county));
                dispatch(updateObjectif(data.objectif === null ? "" : data.objectif));
                dispatch(updateProfilInvestisseur(data.profil === null ? "" : data.profil));
                dispatch(updateCapitalInitial(data.capital_initial === null ? "" : data.capital_initial));
                dispatch(updateVersementAnnuel(data.versement === null ? "" : data.versement));
                dispatch(updateDuree(data.duree === null ? "" : data.duree));
                dispatch(updateFirstName(data.firstName === null ? "" : data.first_name));
                dispatch(updateLastName(data.lastName === null ? "" : data.last_name));
                dispatch(updateEmail(data.email === null ? "" : data.email));
                dispatch(updateOptin(data.optin === null ? "" : data.optin));
                dispatch(updatePhone(data.phone === null ? "" : data.phone));
                dispatch(updatePhoneChecked(data.phone_checked === null ? "" : data.phone_checked));
                dispatch(updateStartedSimulation("true"));
                dispatch(updateActualPageIndex(parseInt(data.actual_page)));
            }
        });
    });
}

export default function StartSimulation(props) {
    const dispatch = useDispatch();

    fetch(
        backendUrl +
        "simulations/vie/startSimulation/?newSimulation=false",
        { credentials: "include", crossDomain: true }
    ).then((resp) => {
        resp.json().then((data) => {
            if (data.statut === "success") {
                if (parseInt(data.actualPage) !== 1 || data.family_situation !== "") {
                    dispatch(updateTempPageIndex(parseInt(data.actual_page)));
                }
                dispatch(updateSessionId(data.session_id === null ? "" : data.session_id));
                dispatch(updateTimestamp(data.timestamp === null ? "" : data.timestamp));
                dispatch(updateSituationFamiliale(data.family_situation === null ? "" : data.family_situation));
                dispatch(updateCounty(data.county === null ? "" : data.county));
                dispatch(updateObjectif(data.objectif === null ? "" : data.objectif));
                dispatch(updateProfilInvestisseur(data.profil === null ? "" : data.profil));
                dispatch(updateCapitalInitial(data.capital_initial === null ? "" : data.capital_initial));
                dispatch(updateVersementAnnuel(data.versement === null ? "" : data.versement));
                dispatch(updateDuree(data.duree === null ? "" : data.duree));
                dispatch(updateFirstName(data.firstName === null ? "" : data.first_name));
                dispatch(updateLastName(data.lastName === null ? "" : data.last_name));
                dispatch(updateEmail(data.email === null ? "" : data.email));
                dispatch(updateOptin(data.optin === null ? "" : data.optin));
                dispatch(updatePhone(data.phone === null ? "" : data.phone));
                dispatch(updatePhoneChecked(data.phone_checked === null ? "" : data.phone_checked));
                

                if (parseInt(data.actualPage) === 1 && data.family_situation === null) {
                    dispatch(updateStartedSimulation("true"));
                    dispatch(updateActualPageIndex(parseInt(data.actual_page)));
                }
            }
        });
    });

    return (
        <>
            <Redirect pageIndex={props.pageIndex} />
            <Modal
                continueSimulation={continueSimulation}
                startNewSimulation={startNewSimulation}
            />
        </>
    );
}
