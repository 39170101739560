/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateProfilInvestisseur } from "../Store/store";

export default function ProfilInvestisseur(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />

            <Page
                title="Quel est votre profil investisseur ?"
                subtitle="Le profil de risque nous permet de définir votre appétence au risque. Plus le risque est important, plus le rendement espéré est important. Vous pouvez à tout moment définir votre profil de risque avec un conseiller et le changer en cours d'investissement. "
                pageType="picklist"
                values={["J'ai un profil prudent. La sécurité de mon investissement est ma priorité.", "J'ai un profil équilibré. Je recherche autant la sécurité que la performance.", "J'ai un profil dynamique. Je recherche un maximum de performance.", "Je ne sais pas. Je veux le définir avec le conseiller."]}
                reduxStateName="profilInvestisseur"
                reduxAction={updateProfilInvestisseur}
                myNameDb="profil"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
