/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { previousPage, sendDataDb } from "../../Store/store";
import "./Arrow.scss";

function goPreviousPage(dispatch, mySessionId, myTimestamp) {
    dispatch(sendDataDb(null));
    dispatch(previousPage({ sessionId: mySessionId, timestamp: myTimestamp }));
}

export default function LeftArrow(props) {
    const dispatch = useDispatch();
    let mySessionId = useSelector((state) => state.backend.sessionId);
    let myTimestamp = useSelector((state) => state.backend.timestamp);

    return (
        <div className="left-arrow arrow no-display-phone">
            <button
                onClick={() => {
                    goPreviousPage(dispatch, mySessionId, myTimestamp);
                }}
                style={{
                    display:
                        props.pageIndex === 0
                            ? "none"
                            : "block",
                }}
            >
                <svg
                    style={{ transform: "rotate(180deg)" }}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.125 10H16.875"
                        stroke="white"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.25 4.375L16.875 10L11.25 15.625"
                        stroke="white"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </button>
        </div>
    );
}
