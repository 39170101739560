/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
// import { checkInitCapital } from "../Config/utils";
import { updateVersementAnnuel } from "../Store/store";
import { regexRemoveSpaces } from "../Config/utils";



function checkData(value) {
    const numerousValue = parseInt(regexRemoveSpaces(value));
    return numerousValue > 0;

}

export default function VersementAnnuel(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Page
                title="Quel somme voulez-vous verser annuellement ?"
                subtitle="C'est la somme que vous allez allouer tous les ans pour votre épargne."
                pageType="input"
                type="numerous"
                placeholder="Ex: 1200 €"
                reduxAction={updateVersementAnnuel}
                reduxStateName="versement"
                dataType="money-integer"
                myNameDb="versement"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
                checkData={checkData}
            />
        </div>
    );
}