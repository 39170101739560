import React from "react";
// import Couvertures from "../Pages/Couvertures";
// import DateNaissance from "../Pages/DateNaissance";
// import DureeEmprunt from "../Pages/DureeEmprunt";
// import Fumeur from "../Pages/Fumeur";
// import MontantEmprunt from "../Pages/MontantEmprunt";
// import Quotite from "../Pages/Quotite";
// import StatutProfessionnel from "../Pages/StatutProfessionnel";
// import TypePret from "../Pages/TypePret";
// import FirstName from "../Pages/FirstName";
// import LastName from "../Pages/LastName";
// import Email from "../Pages/Email";
// import Phone from "../Pages/Phone";
import SituationFamiliale from "../Pages/SituationFamiliale";
import Activite from "../Pages/Activite";
import Objectif from "../Pages/Objectif";
import ProfilInvestisseur from "../Pages/ProfilInvestisseur";
import LastName from "../Pages/LastName";
import Email from "../Pages/Email";
import Phone from "../Pages/Phone";
import Results from "../Pages/Results";
import Simulation from "../Pages/Simulation";
import DureeEmprunt from "../Pages/DureeEmprunt";
import VersementAnnuel from "../Pages/VersementMensuel";

export const simulateur_name = "Assurance vie";
export const categories = ["Famille", "Activité", "Finances","Informations"];
// eslint-disable-next-line react/jsx-key
export const defaultAllPages = [ <SituationFamiliale />, <Activite/>, <Objectif/>, <ProfilInvestisseur/>, <Simulation/>, <VersementAnnuel/>, <DureeEmprunt/>, <LastName/>, <Email/>, <Phone/>, <Results/>];
export const ACTUAL_YEAR = 2022;
export const backendUrl = "https://backend.lexem.io/";