/* eslint-disable react/prop-types */
// import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Redirect(props) {
    let actualPageIndex = useSelector(
        (state) => state.navigation.actualPageIndex
    );
    const navigate = useNavigate();
    setTimeout(() => {
        if (props.pageIndex !== actualPageIndex - 1){
            navigate("/page" + actualPageIndex);
        }
    }, 100);

    return "";
}