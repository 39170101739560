/* eslint-disable react/prop-types */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { putSpaces, regexRemoveSpaces } from "../../../../Config/utils";
import { prepareDataDB, updateNextArrowState, updateOptin } from "../../../../Store/store";
import "./Input.scss";
import Optin from "./Optin";

function handleOptin(event, dispatch, inputValue, checkData, mySessionId, myTimestamp){

    if (checkData(inputValue) && event.target.checked) {
        dispatch(updateNextArrowState("activate"));
    } else {
        dispatch(updateNextArrowState("disable"));
    }

    dispatch(
        prepareDataDB({
            sessionId: mySessionId,
            timestamp: myTimestamp,
            nameDb: "optin",
            content: event.target.checked.toString()
        })
    );

    dispatch(updateOptin(event.target.checked.toString()));
}

function handleChanges(
    event,
    updateValue,
    dispatch,
    checkData,
    dataType,
    mySessionId,
    myTimestamp,
    myNameDb,
    optin,
    optinValue
) {
    const value = event.target.value;
    localStorage.setItem(myNameDb, value);

    if (dataType === "money-integer") {
        dispatch(
            updateValue(
                putSpaces(parseInt(regexRemoveSpaces(event.target.value)), 3)
            )
        );
        dispatch(
            prepareDataDB({
                sessionId: mySessionId,
                timestamp: myTimestamp,
                nameDb: myNameDb,
                content: putSpaces(
                    parseInt(regexRemoveSpaces(event.target.value)),
                    3
                ),
            })
        );

        if (checkData(value)) {
            if (optin && optinValue == "true"){
                dispatch(updateNextArrowState("activate"));
            }else{
                if (!optin){
                    dispatch(updateNextArrowState("activate"));
                }else{
                    dispatch(updateNextArrowState("disable"));
                }
            }
        } else {
            dispatch(updateNextArrowState("disable"));
        }

    } else if (dataType === "integer") {
        dispatch(updateValue(parseInt(regexRemoveSpaces(event.target.value))));
        dispatch(
            prepareDataDB({
                sessionId: mySessionId,
                timestamp: myTimestamp,
                nameDb: myNameDb,
                content: parseInt(regexRemoveSpaces(event.target.value)),
            })
        );

        if (checkData(value)) {
            if (optin && optinValue == "true"){
                dispatch(updateNextArrowState("activate"));
            }else{
                if (!optin){
                    dispatch(updateNextArrowState("activate"));
                }else{
                    dispatch(updateNextArrowState("disable"));
                }
            }
        } else {
            dispatch(updateNextArrowState("disable"));
        }

    } else if (dataType === "string") {
        dispatch(updateValue(event.target.value));
        dispatch(
            prepareDataDB({
                sessionId: mySessionId,
                timestamp: myTimestamp,
                nameDb: myNameDb,
                content: event.target.value,
            })
        );

        if (checkData(value)) {
            if (optin && optinValue == "true"){
                dispatch(updateNextArrowState("activate"));
            }else{
                if (!optin){
                    dispatch(updateNextArrowState("activate"));
                }else{
                    dispatch(updateNextArrowState("disable"));
                }
            }
        } else {
            dispatch(updateNextArrowState("disable"));
        }
    } else if (dataType === "phone") {
        const parsedValue = putSpaces(regexRemoveSpaces(event.target.value), 2);

        dispatch(
            updateValue(parsedValue)
        );
        dispatch(
            prepareDataDB({
                sessionId: mySessionId,
                timestamp: myTimestamp,
                nameDb: myNameDb,
                content: parsedValue,
            })
        );

        if (checkData(parsedValue)) {
            if (optin && optinValue == "true"){
                dispatch(updateNextArrowState("activate"));
            }else{
                if (!optin){
                    dispatch(updateNextArrowState("activate"));
                }else{
                    dispatch(updateNextArrowState("disable"));
                }
            }
        } else {
            dispatch(updateNextArrowState("disable"));
        }
    }
}

function checkDataOnLoad(dispatch, checkData, optin, optinValue) {
    setTimeout(() => {
        if (document.querySelector("input") !== null) {
            const value = document.querySelector("input").value;

            if (checkData(value)) {
                if (optin && optinValue == "true"){
                    dispatch(updateNextArrowState("activate"));
                }else{
                    if (!optin){
                        dispatch(updateNextArrowState("activate"));
                    }else{
                        dispatch(updateNextArrowState("disable"));
                    }
                }
            } else {
                dispatch(updateNextArrowState("disable"));
            }
        }
    }, 500);
}

export default function     Input(props) {
    const dispatch = useDispatch();
    let valueInput = useSelector(
        (state) => state.userInput[props.reduxStateName]
    );
    let mySessionId = useSelector((state) => state.backend.sessionId);
    let myTimestamp = useSelector((state) => state.backend.timestamp);
    let optinValue = useSelector((state) => state.userInput.optin);


    console.log("hello");
    const updateValue = props.reduxAction;
    checkDataOnLoad(dispatch, props.checkData, props.optin, optinValue);

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            document.querySelector(".right-arrow.arrow button").click();
        }
    }

    return (
        <div className="input-container">
            <div className="input-borders">
                {props.icon}
                <input
                    onKeyDown={(event) => {
                        handleKeyDown(event);
                    }}
                    type={props.type}
                    value={valueInput}
                    placeholder={props.placeholder}
                    onChange={(event) => {
                        handleChanges(
                            event,
                            updateValue,
                            dispatch,
                            props.checkData,
                            props.dataType,
                            mySessionId,
                            myTimestamp,
                            props.myNameDb,
                            props.optin,
                            optinValue
                        );
                    }}
                />
            </div>
            {props.optin ? <Optin onChange={(event) => {handleOptin(event, dispatch, valueInput, props.checkData, mySessionId, myTimestamp);}} value={optinValue} text={<>Je reconnais avoir pris connaissance et accepté les <a target="_blank" rel="noreferrer" href="https://www.lexem.io/protection-des-donnees">conditions générale d&apos;utilisation</a></>}/> : ""}
        </div>
    );
}
