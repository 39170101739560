/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateDuree } from "../Store/store";

function checkData(value) {
    const numerousValue = parseInt(regexRemoveSpaces(value));
    return numerousValue > 0 && numerousValue <= 30;
}

export default function DureeEmprunt(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Page
                title={
                    <>
                        Quelle est la durée estimée de votre épargne (
                        <span style={{ color: "#015de6" }}>EN ANNÉES</span>) ?
                    </>
                }
                subtitle="La durée du placement dépend de vos objectifs. La durée minimum recommandée est de 8 ans."
                pageType="input"
                type="numerous"
                placeholder="Ex: 15 ans"
                reduxAction={updateDuree}
                reduxStateName="duree"
                checkData={checkData}
                dataType="money-integer"
                myNameDb="duree"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
