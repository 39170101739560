import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { defaultAllPages } from "./Config/config";
import Results from "./Pages/Results";

const container = document.getElementById("root");
const root = createRoot(container);

function AddExtraProps(Component, extraProps) {
    return <Component.type {...Component.props} {...extraProps} />;
}

let allRoutes = [<Route key="0" path="*" element={<Navigate to="/page1" />} />];

for (let i = 0; i < defaultAllPages.length; i++) {
    allRoutes.push(
        < Route
            key={i}
            path={"/page" + (i + 1)}
            element={
                < App
                    component={
                        AddExtraProps(defaultAllPages[i], {
                            pageIndex: i,
                            totalPages: defaultAllPages.length,
                        })
                    }
                />
            }
        />
    );
}

root.render(
    <BrowserRouter>
        <Routes>
            {allRoutes}
            <Route path="/results" element={<App component={<Results pageIndex={defaultAllPages.length} />} />} />
        </Routes>
    </BrowserRouter>
);
