/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateCounty } from "../Store/store";

export default function Activite(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />

            <Page
                title="Dans quel canton travaillez-vous ?"
                subtitle="Pour identifier les meilleurs dispositifs de votre canton"
                pageType="picklist"
                values={["Vaud, Valais, Neuchâtel", "Bâle, Jura, Soleure, Berne", "Genève", "Fribourg", "Autre"]}
                reduxStateName="county"
                reduxAction={updateCounty}
                myNameDb="county"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
