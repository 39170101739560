export const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

// Function used to remove all chars that's are not numerous from a string
export function regexRemoveSpaces(value) {
    return value.replace(/\D+/g, "") === "" ? "0" : value.replace(/\D+/g, "");
}

// Function used to put spaces every n chars in a string
export function putSpaces(number, n) {
    var numberString;
    if (typeof number == "string") {
        number = number.replaceAll(" ", "");
        numberString = number;
    } else {
        numberString = number.toString();
    }

    if (!isNaN(numberString)) {
        let toReturn = "";
        for (let i = 0; i < numberString.length; i++) {
            if (i % n === 0 && i !== 0) {
                toReturn += " ";
            }

            toReturn += numberString[numberString.length - 1 - i];
        }

        return toReturn.split("").reverse().join("");
    }

    return "";
}

// Function used to check the email
export function checkEmail(value) {
    const regex =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[-\x21\x23-\x5b\x5d-\x7f]|\\[-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[-\x21-\x5a\x53-\x7f]|\\[-\x7f])+)\])/;
    return regex.test(value);
}

export function checkInitCapital(val) {
    console.log(val);
    if (!val)
        return false;
    if (isNaN(val) || parseInt(val) <= 0 )
        return false;
    return true;
}
