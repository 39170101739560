import "./ModalPhone.scss";
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putSpaces, regexRemoveSpaces } from "../../../Config/utils";
import { updatePhone } from "../../../Store/store";

function handleBadPhoneResend(setBadPhone, badPhoneButton, sendSms, phone) {
    if (badPhoneButton == "active") {
        setBadPhone(false);
        sendSms(phone);
    }
}

function handleBadPhoneInput(event, dispatch, setBadPhoneButton) {
    const value = putSpaces(regexRemoveSpaces(event.target.value), 2);
    if (value.length == 14) {
        setBadPhoneButton("active");
    } else {
        setBadPhoneButton("disable");
    }

    dispatch(updatePhone(value));
}

export default function ModalPhone(props) {
    const [code, setCode] = useState(undefined);
    const [badPhone, setBadPhone] = useState(false);
    const [badPhoneButton, setBadPhoneButton] = useState("active");
    const [showError, setShowError] = useState(false);
    const [smsSent, setSmsSent] = useState(false);

    let phone = useSelector((state) => state.userInput.phone);

    const dispatch = useDispatch();

    useEffect(() => {
        props.sendSms(phone);
    }, []);

    if (badPhone) {
        return (
            <div className="modal-phone">
                <div className="modal-content">
                    <h4 className="title-bad-phone">
                        Veuillez saisir votre numéro de téléphone
                    </h4>
                    <input
                        type={"text"}
                        value={phone}
                        onChange={(event) => {
                            handleBadPhoneInput(
                                event,
                                dispatch,
                                setBadPhoneButton
                            );
                        }}
                    />
                    <button
                        className={"resendCode " + badPhoneButton}
                        onClick={() => {
                            handleBadPhoneResend(
                                setBadPhone,
                                badPhoneButton,
                                props.sendSms,
                                phone
                            );
                        }}
                    >
                        Envoyer le code
                    </button>
                </div>
            </div>
        );
    }

    if (!smsSent) {
        props.sendSms(props.phone);
        setSmsSent(true);
    }

    return (
        <div className="modal-phone">
            <div className="modal-content">
                <h4 className="h4">Verifions votre numéro de télephone</h4>
                <h5>
                    {" "}
                    Nous avons envoyé un sms au {props.phone},<br /> saisissez
                    le code reçu{" "}
                </h5>
                <input
                    type="text"
                    onChange={(x) => {
                        setCode(x.target.value);
                    }}
                />
                <div className="row">
                    <button
                        className="grey disable"
                        onClick={() => {
                            props.sendSms(props.phone);
                        }}
                    >
                        Renvoyer un sms
                    </button>
                    <button
                        onClick={() => {
                            props.validate(
                                code,
                                setShowError
                            );
                        }}
                        className="colored"
                    >
                        Verifier
                    </button>
                </div>
                {showError ? (
                    <p className="bad-token">
                        Erreur ! Votre code de vérification n&apos;est pas
                        valide
                    </p>
                ) : (
                    ""
                )}
                <p className="text-bad-phone">
                    Si vous avez rentré un mauvais numéro de téléphone, cliquez{" "}
                    <span
                        onClick={() => {
                            setBadPhone(true);
                        }}
                    >
                        ici
                    </span>{" "}
                    pour le modifier
                </p>
            </div>
        </div>
    );
}
