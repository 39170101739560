/* eslint-disable react/prop-types */
import React from "react";
import "./Cards.scss";

export default function Cards(props) {
    return (
        <div className={"card " + props.className}>
            <p className="text">{props.text}</p>
            <p className="numerous">{props.numerous} €</p>
        </div>
    );
}
