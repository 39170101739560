/* eslint-disable react/prop-types */
import { React } from "react";
import Header from "../Components/Simulation/Header";
import "./Results.scss";
import person from "../Pictures/person.jpg";
import axios from "axios";
import { useState, useEffect } from "react";
import Chart from "../Components/Chart";
import FinalResultsMobile from "../Components/FinalResultsMobile";
import { FaInfoCircle } from "react-icons/fa";
import ModalPhone from "../Components/Simulation/utils/ModalPhone";
import { useDispatch, useSelector } from "react-redux";
import { backendUrl } from "../Config/config";
import { updatePhoneChecked } from "../Store/store";
import Redirect from "../Components/Redirect";
import { putSpaces, regexRemoveSpaces } from "../Config/utils";
import Cards from "../Components/Results/Cards";

export default function Results(props) {
    const [json, setJson] = useState(undefined);
    const [a, setA] = useState(undefined);
    const isVerif = useSelector((state) => state.backend.phoneChecked);
    const val = useSelector(state => state.userInput);
    const dispatch = useDispatch();
    const session_id = useSelector(state => state.backend.sessionId);
    const timestamp = useSelector(state => state.backend.timestamp);
    const lastName = useSelector(state => state.userInput.lastName);
    const email = useSelector(state => state.userInput.email);
    const phone = useSelector(state => state.userInput.phone);
    const situationFamiliale = useSelector(state => state.userInput.situationFamiliale);
    const county = useSelector(state => state.userInput.county);
    const objectif = useSelector(state => state.userInput.objectif);
    const profilInvestisseur = useSelector(state => state.userInput.profilInvestisseur);
    const capitalInitial = useSelector(state => state.userInput.initialCapital);
    const versementAnnuel = useSelector(state => state.userInput.versement);
    const duree = useSelector(state => state.userInput.duree);






    useEffect(() => {
        axios
            .get(
                `${backendUrl}simulations/vie/results/index.php/?initialpayment=${regexRemoveSpaces(capitalInitial)}&annualpayment=${regexRemoveSpaces(versementAnnuel)}&lasting=${regexRemoveSpaces(duree)}&profilInvestisseur=${profilInvestisseur}`
            )
            .then((val) => {
                setJson(val.data);
            });
        axios
            .get(
                `${backendUrl}simulations/vie/results/index.php/?initialpayment=${regexRemoveSpaces(capitalInitial)}&annualpayment=${regexRemoveSpaces(versementAnnuel)}&lasting=${regexRemoveSpaces(duree)}&profilInvestisseur=livret A`
            )
            .then((val) => {
                setA(val.data);
            });
    }, []);

    const validate = async (code, setShowError) => {
        const res = await fetch(backendUrl + "simulations/vie/2fa/check/", { credentials: "include", crossDomain: true, method: "POST", body: JSON.stringify({"phone": val.phone, "token": code})});
        const a = await res.json();
        if (a.statut === "success"){
            dispatch(updatePhoneChecked("true"));
            fetch(backendUrl + "simulations/vie/update/index.php", {
                method: "POST",
                body: JSON.stringify({
                    "session_id": session_id,
                    "timestamp": timestamp,
                    "phone_checked": "true",
                }),
                credentials: "include",
                crossDomain: true,
            });
            fetch(backendUrl + "simulations/vie/sendData/index.php", {
                method: "POST",
                body: JSON.stringify({
                    "firstName": "M.",
                    "lastName": lastName,
                    "email": email,
                    "phone": phone,
                    "familySituation": situationFamiliale,
                    "county": county,
                    "objectif": objectif,
                    "profil": profilInvestisseur,
                    "capital_initial": capitalInitial,
                    "versement": versementAnnuel,
                    "duree": duree,
                    "capital_constitue": parseInt(json[duree - 1].saving).toString(),
                }),
                credentials: "include",
                crossDomain: true,
            });
        }else {
            setShowError(true);
        }
    };

    const sendSms = async () => {
        const a = await fetch(backendUrl + "simulations/vie/2fa/send/", {credentials: "include", crossDomain: true, method: "POST", body: JSON.stringify({"phone": val.phone})
        });
        
        console.log(a);
    };

    return (
        <div className="result-page">
            <Redirect pageIndex={props.pageIndex} />
            {!(isVerif === "true") ? (
                <ModalPhone
                    phone={val.phone}
                    validate={validate}
                    sendSms={sendSms}
                />
            ) : (
                <div></div>
            )}
            <Header showProgressBar={false} />
            <div className="content">
                <div className="uncentered">
                    <img className="image-person" src={person} alt="Photo d'un visage" />
                    <h2>Bienvenue Mme./Mr. {lastName} !</h2>
                </div>
                <p className="welcome-text">
                Félicitations ! Vous avez entrepris des démarches pour <b>mettre en place une Assurance-Vie</b> afin de <b>préparer votre avenir et diversifier votre patrimoine</b> et nous pouvons vous aider pour ça !
                Notre Algorithme a calculé le <b>capital disponible</b> sur votre assurance-vie !

                </p>
            </div>
            <h2 style={{textDecoration: "underline", margin: "0px 12vw"}}>Simulation de votre capital disponible avec Lexem</h2>
            <p style={{margin: "25px 12vw"}}>Avec une hypothèse d&apos;un rendement de {profilInvestisseur === "J'ai un profil prudent. La sécurité de mon investissement est ma priorité." ? "2,5 %" : profilInvestisseur === "J'ai un profil équilibré. Je recherche autant la sécurité que la performance." ? "3,5 %" : profilInvestisseur === "J'ai un profil dynamique. Je recherche un maximum de performance." ? "5 %" : "3,5 %"} annuel, vous obtiendrez les résultats suivants concernant votre capital</p>
            <div className="cards-container">
                <Cards
                    text="Capital constitué"
                    numerous={json !== undefined ? putSpaces(parseInt(json[duree - 1].saving).toString(), 3) : ""}
                />
                <Cards
                    text="Intérêts générés"
                    numerous={json !== undefined ? putSpaces(parseInt(json[duree - 1].saving - json[duree - 1].annualPayment).toString(), 3) : ""}
                />
            </div>
            <Chart
                values={json}
                livret={a}
                year={duree}
                isSet={isVerif === "true"}
            ></Chart>
            <FinalResultsMobile
                values={json}
                year={duree}
            />
            <div className="text-assurance">
                *L&apos;assurance vie est un outil flexible et qui répond à de nombreux
                critères pour les frontaliers. Il peut aussi bien vous permettre de
                préparer votre retraite que de vous constituer une épargne pour financer
                un projet particulier. C&apos;est également une excellente solution
                visant à diversifier votre patrimoine et fructufier votre capital
            </div>
            <div className="flex-div">
                <div className="we-will-call-you">
                    <FaInfoCircle fontSize={"28px"} style={{ flexShrink: 0 }} />
                    <p>
                        Un(e) expert(e) Lexem vous contactera dans 48 h
                        pour effectuer un bilan patrimonial gratuit.
                    </p>
                </div>
                <h4 style={{fontSize: "20px", textDecoration: "underline"}} >Les étapes pour la mise en place d&apos;une solution d&apos;épargne avec Lexem</h4>
                <div className="steps-container">
                    <div className="step">
                        <div className="line1">
                            <div className="icon-number">1</div>
                            <p>Vous avez obtenu vos résultats de simulation</p>
                        </div>
                        <div className="line2"></div>
                    </div>
                    <div className="step colored">
                        <div className="line1">
                            <div className="icon-number">2</div>
                            <p>Profitez d&apos;un bilan patrimonial gratuitement</p>
                        </div>
                        <div className="line2">
                            Un(e) expert(e) Lexem vous contactera dans les 48h pour analyser effectuer un bilan patrimonial gratuitement avec vous
                        </div>
                    </div>
                    <div className="step">
                        <div className="line1">
                            <div className="icon-number">3</div>
                            <p>Choisissez le montant de votre épargne</p>
                        </div>
                        <div className="line2">
                            Votre conseiller(ère) vous orientera vers le montant le plus
                            adapté à vos moyens et votre situation
                        </div>
                    </div>
                    <div className="step">
                        <div className="line1">
                            <div className="icon-number">4</div>
                            <p>Mettez en place la solution et consultez l&apos;évolution de votre épargne en ligne à tout moment !</p>
                        </div>
                        <div className="line2"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
