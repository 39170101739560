/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { updateLastName } from "../Store/store";

function checkData(value) {
    return value.length >= 2 && value.length <= 255;
}

export default function LastName(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Page
                title="Quel est votre nom ?"
                subtitle="Pour mieux vous connaître"
                pageType="input"
                placeholder="Ex: Doe"
                reduxAction={updateLastName}
                reduxStateName="lastName"
                dataType="string"
                checkData={checkData}
                myNameDb="last_name"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
