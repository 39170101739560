/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
// import { checkInitCapital } from "../Config/utils";
import { regexRemoveSpaces } from "../Config/utils";
import { updateCapitalInitial } from "../Store/store";



function checkData(value) {
    const numerousValue = parseInt(regexRemoveSpaces(value));
    return numerousValue >= 0;

}

export default function Simulation(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Page
                title="Quel est votre capital initial voulez-vous verser ?"
                subtitle="Vous pouvez placer un montant inital afin de faire fructifier plus rapidement votre placement. Cependant, un capital inital n'est pas obligatoire"
                pageType="input"
                type="numerous"
                placeholder="Ex: 1000 €"
                reduxAction={updateCapitalInitial}
                reduxStateName="initialCapital"
                dataType="money-integer"
                myNameDb="capital_initial"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
                checkData={checkData}
            />
        </div>
    );
}
