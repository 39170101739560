/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updatePhone } from "../Store/store";

const frenchFlag = (
    <svg
        style={{ flexShrink: "0" }}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 2.8375C14.7745 2.302 13.423 2 12 2C10.577 2 9.2255 2.302 8 2.8375V21.162C9.2255 21.698 10.577 22 12 22C13.423 22 14.7745 21.698 16 21.1625V2.8375Z"
            fill="#ECEFF1"
        />
        <path
            d="M22 11.9999C22 7.9004 19.5305 4.3814 16 2.8374V21.1619C19.5305 19.6184 22 16.0994 22 11.9999Z"
            fill="#FF3D00"
        />
        <path
            d="M2 11.9999C2 16.0994 4.4695 19.6184 8 21.1624V2.8374C4.4695 4.3814 2 7.9004 2 11.9999Z"
            fill="#3F51B5"
        />
    </svg>
);

function checkData(value) {
    return value.length === 14;
}

export default function Phone(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Page
                title="Quel est votre numéro de téléphone ?"
                subtitle="Pour accéder à vos résultats."
                pageType="input"
                type="numerous"
                placeholder="Ex: 06 01 02 03 04"
                reduxAction={updatePhone}
                reduxStateName="phone"
                icon={frenchFlag}
                checkData={checkData}
                dataType="phone"
                myNameDb="phone"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
