import React from "react";
/* eslint-disable react/prop-types */
import ReactApexChart from "react-apexcharts";
import "./Chart.scss";

export default function Chart(props) {

    var annualPayments = [];
    var saving = [];

    if (props.values && props.isSet) {
        for (var i = 0; i != props.year; i++) {
            annualPayments.push(parseInt(props.values[i].annualPayment));
            saving.push(parseInt(props.values[i].saving));
        }
        console.log(annualPayments, saving);
        let options = {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight"
            },
            title: {
                text: "Evolution de votre épargne",
                align: "center"
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: [{

                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
            ],

        };

        let series = [
            {
                name: "Epargne constituée",
                data: saving,
                color: "#FF0000"
            }, {
                name: "Versements annuels",
                data: annualPayments,
                color: "#0000FF"
            },
        ];

        return (<div>
            <ReactApexChart className={"chart mobile"} options={options} series={series} />
        </div>);
    }
    return (<div></div>);
}