/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateObjectif } from "../Store/store";

export default function Objectif(props) {
    return (
        <div className="page-container">
            <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
            <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />

            <Page
                title="Quel est votre objectif en mettant en place une assurance-vie ?"
                subtitle=""
                pageType="picklist"
                values={["Préparer votre retraite", "Transmettre votre patrimoine", "Proteger votre famille et vos proches", "Financer un projet personnel", "Fructifier votre épargne et diversifier vos placements"]}
                reduxStateName="objectif"
                reduxAction={updateObjectif}
                myNameDb="objectif"
                pageIndex={props.pageIndex}
                totalPages={props.TotalPages}
            />
        </div>
    );
}
