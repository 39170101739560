import React from "react";
/* eslint-disable react/prop-types */
import ReactApexChart from "react-apexcharts";
import "./Chart.scss";

export default function Chart(props) {

    var annualPayments = [];
    var saving = [];

    if (props.values && props.livret) {
        console.log(props.livret);
        for (const elem of props.values) {
            annualPayments.push(parseInt(elem.annualPayment));
            saving.push(parseInt(elem.saving));
        }

        let options = {
            chart: {
                height: 350,
                type: "line",
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "straight",
                width: [4, 4, 2],
                dashArray: [0, 0, 5]
            },
            title: {
                text: "Evolution de votre épargne",
                align: "center"
            },
            grid: {
                row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: [{
                axisBorder: {
                    show: true,
                    color: "#015de6",
                },
                tickAmount: 10,
                labels: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
            ],
            annotations: {

                xaxis: [
                    {
                        x: parseInt(props.year),
                        marker: {
                            size: 8
                        },
                        label: {
                            offsetY: 350,
                            orientation: "horizontal",
                            borderColor: "#FFFFFF",
                            text: "La fin de votre épargne",
                            style: {
                                color: "#015de6",
                                fontSize: "16px",
                                fontWeight: "600"
                            }
                        }
                    }
                ]
            },

        };

        let series = [
            {
                name: "Epargne constituée",
                data: saving,
                color: "#23DC3D"
            }, {
                name: "Versements annuels",
                data: annualPayments,
                color: "#015de6"
            }
        ];

        console.log(options.annotations.points);
        return (<div>
            <ReactApexChart className={"chart pc " + props.className} options={options} series={series} />
        </div>);
    }
    return (<div></div>);
}