/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import "./Modal.scss";

export default function Modal(props) {
    const dispatch = useDispatch();

    return (
        <div className="modal-start-simulation">
            <div className="modal-content">
                <h4 >Vous avez déjà effectué une simulation</h4>
                <button
                    onClick={() => {
                        props.continueSimulation(dispatch);
                    }}
                >
                    Reprendre
                </button>
                <button
                    onClick={() => {
                        props.startNewSimulation(dispatch);
                    }}
                    className="grey mb-10"
                >
                    Recommencer
                </button>
            </div>
        </div>
    );
}
